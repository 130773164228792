/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable camelcase */

import { ICartItem } from '@/entities/Checkout/domain/Checkout.domain';
import URL from '../../app/utils/urlsUtils';
import {
    ActivityArticle,
    ActivityArticlePreview,
    ActivityCartItem,
    ActivityPreview,
    ImpressionPayload,
    ImpressionResolver,
    ProductDetail,
    ProductDetailPayload,
    ProductInListPayload,
    ProductInListResolver,
    ProductPayload,
    ProductResolver,
    ProductCartPayload,
    ProductCartItem,
    Order,
    Purchase,
    ImpressionPayLoadActivity,
    IImpressionForSnowplow,
} from './Analytics.types';
import { IApiCartItem } from '@/entities/Cart/domain/Cart.domain';
import { IRecommendsItem } from '@/shared/FeaturedActivities/ui/featuredActivitiesPreview/FeaturedActivitiesPreview.types';
import { IActivity } from '@/entities/Activity/domain/Activity.domain';
import {
    IDestinationCategory,
    IDestinationList,
} from '@/entities/Destination/domain/Destination.domain';

function categoryReplace(name: string) {
    return name.replace(' - ', '|');
}

export const createCategoryNameFromPreview = (activity: ActivityPreview) => {
    if (activity.analytics_data && activity.analytics_data.category) {
        return activity.analytics_data.category;
    }

    return '';
};

export const createCategoryNameFromArticle = (activity: ActivityArticle) => {
    if (
        activity.city_name &&
        activity.activity_categories &&
        activity.activity_categories.length > 0
    ) {
        return `${activity.city_name}|${activity.activity_categories[0].name}`;
    }

    return '';
};

export const createCategoryNameFromArticlePreview = (
    activity: ActivityArticlePreview | IActivity | IRecommendsItem
) => {
    if ((activity as IActivity).city_name) {
        return createCategoryNameFromArticle(activity as ActivityArticle); // temp hotfix
    } else {
        return (activity as IRecommendsItem).analytics_data?.category?.replace(' - ', '|');
    }
};

export const createCategoryNameFromCartItem = (activity: ActivityCartItem) => {
    if (activity.product_city_info && activity.product_category_info) {
        return `${activity.product_city_info.name}|${
            activity.product_activity || activity.product_category_info.name
        }`;
    }

    return '';
};

// Impressions
export const createImpressionFromPreview =
    ({ listName, categoryName, position }: ImpressionPayload) =>
    (activity: ImpressionPayLoadActivity) => ({
        list: listName,
        category: categoryReplace(categoryName),
        position,
        name: activity.name,
        id: activity.id,
        price: activity.price,
        brand: activity.analytics_data.brand,
    });

export const createImpressionFromArticle =
    ({
        listName,
        categoryName,
        position,
    }: ImpressionPayload): ImpressionResolver<ActivityArticle | ActivityArticlePreview> =>
    (activity) => ({
        list: listName,
        category: categoryReplace(categoryName),
        position,
        id: activity.id,
        name: activity.name,
        price: activity.price,
        brand: activity.title,
    });

export const createImpressionFromCartItem =
    ({
        listName,
        categoryName,
        position,
    }: ImpressionPayload): ImpressionResolver<ActivityCartItem> =>
    (activity) => ({
        list: listName,
        category: categoryReplace(categoryName),
        position,
        name: activity.product_name,
        id: activity.product_id,
        price: activity.partner_price,
        brand: activity.partner_title,
    });

export const createImpressionForSnowplow =
    ({ listName, categoryName, position }: ImpressionPayload) =>
    (activity: IImpressionForSnowplow) => ({
        product: activity.name,
        productId: `${activity.id}`,
        list: listName,
        position: Number(position),
        destinationName: activity?.city.name || '',
        destinationId: `${activity.city.id}`,
        categoryName: activity.category.name || categoryName,
        categoryId: `${activity.category.id}`,
        partnerName: activity.title,
        partnerId: `${activity.partner_id}`,
        price: Number(activity.price),
    });

// Products

export const createProductFromPreview =
    ({ categoryName }: ProductPayload): ProductResolver<ActivityPreview> =>
    (activity) => ({
        category: categoryReplace(categoryName),
        name: activity.name,
        id: activity.id,
        price: activity.price,
        brand: (activity.analytics_data || {}).brand,
    });

export const createProductFromArticlePreview =
    ({ categoryName }: ProductPayload): ProductResolver<ActivityArticlePreview> =>
    (activity) => ({
        category: categoryReplace(categoryName),
        id: activity.id,
        name: activity.name,
        price: activity.price,
        brand: activity.title,
    });

export const createProductFromArticle =
    ({
        categoryName,
    }: ProductPayload): ProductResolver<ActivityArticle> => // !TO-CHECK this function is identical to createProductFromArticlePreview
    (activity) => ({
        category: categoryReplace(categoryName),
        id: activity.id,
        name: activity.name,
        price: activity.price,
        brand: activity.title,
    });

export const createProductFromCartItem =
    ({ categoryName }: ProductPayload): ProductResolver<ActivityCartItem> =>
    (activity) => ({
        category: categoryReplace(categoryName),
        name: activity.product_name,
        id: activity.product_id,
        price: activity.partner_price,
        brand: activity.partner_title,
    });

// Products In List

export const createProductInListFromPreview =
    ({ categoryName, position }: ProductInListPayload): ProductInListResolver<ActivityPreview> =>
    (activity) => ({
        position,
        category: categoryReplace(categoryName),
        name: activity.name,
        id: activity.id,
        price: activity.price,
        brand: activity.analytics_data.brand,
    });

export const createProductInListFromArticlePreview =
    ({
        categoryName,
        position,
    }: ProductInListPayload): ProductInListResolver<
        ActivityArticlePreview | IActivity | IRecommendsItem
    > =>
    (activity) => {
        let brand = '';
        if ((activity as IActivity).title) {
            brand = (activity as IActivity).title;
        } else {
            brand = (activity as IRecommendsItem).analytics_data?.brand;
        }

        return {
            position,
            category: categoryReplace(categoryName),
            id: activity.id,
            name: activity.name,
            price: activity.price,
            brand,
        };
    };

export const createProductInListFromArticle =
    ({
        categoryName,
        position,
    }: ProductInListPayload): ProductInListResolver<ActivityArticle> => // !TO-CHECK this function is identical to createProductInListFromArticlePreview
    (activity) => ({
        position,
        category: categoryReplace(categoryName),
        id: activity.id,
        name: activity.name,
        price: activity.price,
        brand: activity.title,
    });

export const createProductInListFromCartItem =
    ({ categoryName, position }: ProductInListPayload): ProductInListResolver<ActivityCartItem> =>
    (activity) => ({
        position,
        category: categoryReplace(categoryName),
        name: activity.product_name,
        id: activity.product_id,
        price: activity.partner_price,
        brand: activity.partner_title,
    });

// Product Detail

export const createProductDetailFromArticle =
    ({ categoryName }: ProductDetailPayload): ((v: ActivityArticle) => ProductDetail) =>
    (activity) => ({
        category: categoryReplace(categoryName),
        name: activity.name,
        id: activity.id,
        price: activity.price,
        brand: activity.title,
        image: `${URL.image}/activity/${activity.id}/${activity.slug}.jpg`,
        price_strike_out: activity.price_strike_out || undefined,
    });

// Products In Cart

export const createProductsInCartFromCartItems =
    ({
        categoryName,
    }: ProductCartPayload): ((v: IApiCartItem[] | ICartItem[]) => ProductCartItem[]) =>
    (items) => {
        return items
            .map((item) => {
                let name: string, brand: string, ID: string;
                if ((item as ICartItem).trip_name) {
                    const { trip_name, partner_name, id } = item as ICartItem;
                    name = trip_name;
                    brand = partner_name;
                    ID = id;
                } else {
                    const { product_name, partner_title, product_id } = item as IApiCartItem;
                    name = product_name;
                    brand = partner_title;
                    ID = product_id;
                }

                return Object.values(item.tickets).map((ticket) => ({
                    name,
                    id: ID,
                    price: +ticket.price,
                    brand,
                    category: categoryReplace(
                        categoryName ||
                            `${item.product_city_info.name}|${item.product_category_info.name}`
                    ),
                    variant: ticket.name,
                    quantity: +ticket.booked,
                }));
            })
            .reduce((acc, cur) => [...acc, ...cur], []);
    };

// Purchase

export const createPurchase = (order: Order): Purchase => ({
    id: order.id,
    affiliation: 'TripShock',
    revenue: order.gross_total,
    comission: order.comission, // it must be commission instead comission.
    tax: Object.values(order.cart_items).reduce((acc, cur) => {
        if (cur.surcharges) {
            return acc + cur.surcharges;
        }
        return acc;
    }, 0),
    shipping: '0.00',
    coupon: order.accesskey,
});

export const createImpressionFromArticlePreview =
    ({
        listName,
        categoryName,
        position,
    }: ImpressionPayload): ImpressionResolver<
        ActivityArticlePreview | IActivity | IRecommendsItem
    > => // !TO-CHECK this function is identical to createProductInListFromArticlePreview
    (activity) => {
        let brand = '';
        if ((activity as IActivity).title) {
            brand = (activity as IActivity).title;
        } else {
            brand = (activity as IRecommendsItem).analytics_data?.brand;
        }

        return {
            list: listName,
            category: categoryReplace(categoryName),
            position,
            id: activity.id,
            name: activity.name,
            price: activity.price,
            brand,
        };
    };

const purePath = (url: string) => url.split('/things-to-do/')[1].split('?')[0];

const isCategory = (url: string) => {
    return url.includes('category') || !!purePath(url);
};

export const createListNameFromPath = (pathname: string): string => {
    const base = 'Featured Placement';

    if (pathname.includes('/things-to-do/')) {
        return `${base} - ${isCategory(pathname) ? 'Category' : 'Destination'}`;
    }
    if (pathname.includes('/details/')) {
        return `${base} - Activity`;
    }
    if (pathname.includes('/blog')) {
        return `${base} - Blog`;
    }
    if (pathname.includes('/partners')) {
        return `${base} - Partners`;
    }
    if (pathname.includes('/traveler-stories')) {
        return `${base} - Story`;
    }
    if (pathname.includes('/destination')) {
        return `${base} - Visitors Guide`;
    }
    if (pathname.includes('/itinerary')) {
        return `${base} - Activity`;
    }

    return base;
};

export const createListNameGA3 = (
    destination: IDestinationList,
    category?: IDestinationCategory
) => {
    return [destination?.label, category ? category.name : undefined].filter(Boolean).join(' - ');
};

export const createCategoryNameFromListName = (listName: string) => {
    const [label, category] = listName.split(' - ');
    const city = label.split(',')[0];
    if (category) {
        return `${city}|${category}`;
    }
    return city;
};
