import { UrlsProduction, UrlsStaging } from 'src/common/domain/Constants.domain';
import { isProd } from '@/common/app/constants/envs';

type TBaseUrls = {
    api: string;
    content: string;
    redirects: string;
    affiliates: string;
    crypto: string;
    image: string;
    apiImage: string;
    partners: string;
    talkToUs: string;
    iterable: string;
    crs: string;
};

type attractionsUrlProp = {
    destination: string;
    stateCode: string;
    category?: string;
    from?: string;
    to?: string;
};

type slugUrlProp = {
    slug: string;
};

type destinationUrlProp = {
    state: string;
    slug: string;
};

export const buildRootUrl = (pathname = ''): string => {
    const path = pathname
        .trim()
        .replace(/^\/+|\/+$/g, '')
        .replace(/\s/g, '-')
        .replace(/\./g, '')
        .split('/?')[0]
        .toLowerCase();
    return `https://www.tripshock.com/${path}${path ? '/' : ''}`;
};

export const storyPageUrl = ({ slug }: slugUrlProp) => {
    if (slug) {
        return `/traveler-stories/${slug}/`;
    }
    return '/';
};

export const blogPostPageUrl = ({ slug }: slugUrlProp) => {
    if (slug) {
        return `/blog/${slug}/`;
    }
    return '/';
};

export const destinationUrl = ({ state, slug }: destinationUrlProp) => {
    if (state && slug) {
        return `/destination/${state}/${slug}/`;
    }
    return '/';
};

export const destinationStoriesUrl = ({ state, slug }: destinationUrlProp) => {
    if (state && slug) {
        return `/traveler-stories/${state}/${slug}/`;
    }
    return '/';
};

export const destinationPartnersUrl = ({ slug, state }: destinationUrlProp) => {
    if (slug && state) {
        return `/partners/${state}/${slug}/`;
    }
    return '/';
};

export const destinationBlogsUrl = ({ state, slug }: destinationUrlProp) => {
    if (state && slug) {
        return `/blog/${state}/${slug}/`;
    }
    return '/';
};

const URL: TBaseUrls = isProd ? UrlsProduction : UrlsStaging;

export const attractionsUrl = ({
    destination,
    stateCode,
    category,
    from,
    to,
}: attractionsUrlProp) => {
    const qs = from && to ? `?from=${from}&to=${to}` : '';
    if (destination && category) {
        return `/destination/${stateCode}/${destination}/things-to-do/${category.replace(
            /\//g,
            '-'
        )}/${qs}`.toLowerCase();
    }
    if (destination) {
        return `/destination/${stateCode}/${destination}/things-to-do/${qs}`.toLowerCase();
    }
    return '/';
};

export { URL };
export default URL;
