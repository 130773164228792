import { IDateRange } from '@/common/domain/Date.domain';
import { ICategory } from '@/common/service/api/Categories/Categories.domain';
import { IPartnersItem } from '@/common/service/api/Partners/Partners.domain';
import { getLS, setLS } from '@/common/service/storage';
import { IDestination } from '@/entities/Destination/domain/Destination.domain';
import { addDays } from 'date-fns';
import { useRouter } from 'next/router';
import {
    createContext,
    Dispatch,
    memo,
    ReactNode,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { useAppContext, useDateContext } from '@/common/app/contexts';
import { getDestinationCategories, IDestinationCategory } from '@/shared/SearchBar/app/utils';
import { getAttractionsUrl, getFormattedRangeDate, getRangeDateBySlug } from '../utils/dateUtils';
import { getPartner } from '@/common/service/api/Partners/Partners';
import { getSearchRecents } from '../utils/getSearchRecents';
import { trackSearch } from '@/shared/Analytics/GA4';

interface SearchProviderProps {
    children: ReactNode;
    currentDestinationId: number;
    currentCategoryId: number;
    currentPartnerId: number;
}

export interface IAttractionsSearch {
    destination: IDestination;
    category?: ICategory;
    partner?: IPartnersItem;
    dateRange: IDateRange;
}

export interface IDestinationPartners {
    loading: boolean;
    items: IPartnersItem[];
    destination_id?: string;
}

type ContextProps = {
    selectedDestination?: IDestination;
    selectedCategory?: ICategory;
    currentCategory?: ICategory;
    selectedPartner?: IPartnersItem;
    selectedDateRange?: IDateRange;
    recents?: IAttractionsSearch[];
    isExperience: boolean;
    setExperience: Dispatch<SetStateAction<boolean>>;
    destinationCategories: IDestinationCategory[];
    destinationPartners: IDestinationPartners;
    saveDestinationById: (destinationId?: string) => void;
    saveCategoryById: (categoryId?: string) => void;
    savePartnerById: (partnerId?: string) => void;
    saveDateRange: (dateRange?: IDateRange) => void;
    saveRecents: (search: IAttractionsSearch) => void;
    onRecentSearch: (recent: IAttractionsSearch, callback?: () => void) => void;
    onSearch: (value: {
        searchDestination?: IDestination;
        searchCategory?: ICategory;
        searchPartner?: IPartnersItem;
        searchDateRange?: IDateRange;
    }) => void;
};

const DEFAULT_STORE: ContextProps = {
    isExperience: true,
    destinationCategories: [],
    destinationPartners: {
        loading: false,
        items: [],
    },
    setExperience: () => {
        return;
    },
    saveDestinationById: () => {
        return;
    },
    saveCategoryById: () => {
        return;
    },
    savePartnerById: () => {
        return;
    },
    saveDateRange: () => {
        return;
    },
    saveRecents: () => {
        return;
    },
    onSearch: () => {
        return;
    },
    onRecentSearch: () => {
        return;
    },
};

const SearchContext = createContext<ContextProps>(DEFAULT_STORE);

const SearchContextProvider = ({
    children,
    currentCategoryId,
    currentDestinationId,
    currentPartnerId,
}: SearchProviderProps) => {
    const { push, asPath, isReady, pathname } = useRouter();

    const { destinations, categories } = useAppContext();
    const { date } = useDateContext();

    const [currentPath, setCurrentPath] = useState<string>('');
    const [selectedDestination, setSelectedDestination] = useState<IDestination>();
    const [selectedCategory, setSelectedCategory] = useState<ICategory>();
    const [currentCategory, setCurrentCategory] = useState<ICategory>();
    const [selectedPartner, setSelectedPartner] = useState<IPartnersItem>();
    const [selectedDateRange, setSelectedDateRange] = useState<IDateRange>();
    const [recents, setRecents] = useState<IAttractionsSearch[]>([]);

    const [isExperience, setExperience] = useState<boolean>(true);
    const [destinationCategories, setDestinationCategories] = useState<IDestinationCategory[]>([]);
    const [destinationPartners, setDestinationPartners] = useState<IDestinationPartners>({
        loading: false,
        items: [],
    });

    const getRecents = useCallback((): IAttractionsSearch[] => {
        try {
            const lsRecents = getLS('recents');

            if (lsRecents) {
                const parsedRecents =
                    typeof lsRecents === 'string' ? JSON.parse(lsRecents) : lsRecents;

                return getSearchRecents(parsedRecents);
            }
            return [];
        } catch (error) {
            return [];
        }
    }, []);

    const saveCategoryById = useCallback(
        (categoryId?: string) => {
            if (!categories) return;

            const newCategory = categories?.find(
                (category) => String(category.id) === String(categoryId)
            );

            setSelectedCategory(newCategory);
        },
        [categories]
    );

    const savePartnerById = useCallback(
        (partnerId?: number | string) => {
            const newPartner = destinationPartners.items.find(
                (partner) => +partner.id === +(partnerId || '')
            );

            setSelectedPartner(newPartner);
        },
        [destinationPartners.items]
    );

    const getDestinationPartners = useCallback(
        async (destination_id: string) => {
            setDestinationPartners((prev) => {
                if (prev.loading && prev.destination_id === destination_id) {
                    return prev;
                }
                return {
                    loading: true,
                    items: [],
                    destination_id,
                };
            });

            if (destinationPartners.loading) return;

            const { items } = await getPartner({ destination_id, offset: 0, limit: 200 });

            setDestinationPartners({
                loading: false,
                items,
                destination_id,
            });

            if (currentPartnerId) {
                const newPartner = items.find((partner) => +partner.id === +currentPartnerId);

                setSelectedPartner(newPartner);
            }
        },
        [currentPartnerId, destinationPartners.loading]
    );

    const saveDestinationById = useCallback(
        (destinationId?: string) => {
            if (destinationId === selectedDestination?.id) return;

            const newDestination = destinations.find(
                (destination) => String(destination.id) === String(destinationId)
            );

            setSelectedDestination(newDestination);

            const newDestinationCategories = getDestinationCategories(categories, newDestination);
            setDestinationCategories(newDestinationCategories);
            setSelectedPartner(undefined);

            if (!newDestinationCategories.find((i) => i.category?.id === selectedCategory?.id)) {
                setSelectedCategory(undefined);
            }
        },
        [categories, destinations, selectedCategory?.id, selectedDestination?.id]
    );

    const saveDateRange = useCallback((dateRange?: IDateRange) => {
        setSelectedDateRange(dateRange);
    }, []);

    const saveRecents = useCallback(
        (search: IAttractionsSearch) => {
            try {
                const lsRecents = getRecents();

                const newRecents = getSearchRecents([search, ...lsRecents]);
                setRecents(newRecents);

                setLS('recents', newRecents);
            } catch (error) {
                setRecents([]);
                setLS('recents', []);
            }
        },
        [getRecents]
    );

    const setSearchAttractionData = useCallback(
        async (isExperience: boolean) => {
            if (!currentDestinationId) return;

            const newSelectedDestination = destinations.find(
                (i) => +i.id === +currentDestinationId
            );

            if (!newSelectedDestination) return;

            if (isExperience) {
                const newDestinationCategories = getDestinationCategories(
                    categories,
                    newSelectedDestination
                );

                setSelectedDestination(newSelectedDestination);
                setDestinationCategories(newDestinationCategories);
                if (!currentCategoryId) {
                    setSelectedCategory(undefined);
                    return setCurrentCategory(undefined);
                }

                const newSelectedCategory = categories.find((i) => +i.id === currentCategoryId);

                if (newSelectedCategory) {
                    setSelectedCategory(newSelectedCategory);
                    setCurrentCategory(newSelectedCategory);
                }
            } else {
                setSelectedDestination(newSelectedDestination);
                await getDestinationPartners(newSelectedDestination.id);

                if (!currentPartnerId) {
                    setSelectedPartner(undefined);
                }
            }
        },
        [
            categories,
            currentCategoryId,
            currentDestinationId,
            currentPartnerId,
            destinations,
            getDestinationPartners,
        ]
    );

    const onSearch = useCallback(
        ({
            searchDestination,
            searchCategory,
            searchPartner,
            searchDateRange,
        }: {
            searchDestination?: IDestination;
            searchCategory?: ICategory;
            searchPartner?: IPartnersItem;
            searchDateRange?: IDateRange;
        }) => {
            if (!searchDestination) return;

            const today = new Date();
            const todayEnd = addDays(today, 6);

            const dateRangeSearch = {
                dateStart: searchDateRange?.dateStart ?? today,
                dateEnd: searchDateRange?.dateEnd ?? todayEnd,
            };

            const { slug: destinationSlug, name: destinationName, state } = searchDestination;
            const { slug: categorySlug, name: categoryName } = searchCategory ?? {};
            const { url: partnerSlug, title: partnerName } = searchPartner ?? {};

            saveRecents({
                destination: searchDestination,
                category: searchCategory,
                partner: searchPartner,
                dateRange: dateRangeSearch,
            });

            push(
                getAttractionsUrl({
                    destinationSlug,
                    stateCode: state.code,
                    categorySlug,
                    urlDate: dateRangeSearch,
                    partner: partnerSlug,
                })
            );

            if (destinationName) {
                trackSearch({
                    search_term: `${destinationName}${
                        categoryName ? '/' + categoryName : partnerName ? '/' + partnerName : ''
                    }`,
                });
            }
        },
        [saveRecents, push]
    );

    const onRecentSearch = useCallback(
        (recent: IAttractionsSearch, callback?: () => void) => {
            saveDestinationById(recent.destination.id);
            saveCategoryById(recent.category?.id);
            savePartnerById(recent.partner?.id);
            saveDateRange(recent.dateRange);

            onSearch({
                searchDestination: recent.destination,
                searchCategory: recent.category,
                searchDateRange: recent.dateRange,
                searchPartner: recent.partner,
            });

            if (callback) {
                callback();
            }
        },
        [onSearch, saveCategoryById, saveDateRange, saveDestinationById, savePartnerById]
    );

    useEffect(() => {
        const newRecents = getRecents();
        if (newRecents) {
            setRecents(newRecents);
        }
    }, [getRecents]);

    useEffect(() => {
        if (!isExperience) {
            if (
                selectedDestination?.id &&
                selectedDestination.id !== destinationPartners.destination_id
            ) {
                getDestinationPartners(selectedDestination?.id || '');
            }
            setSelectedCategory(undefined);
            setCurrentCategory(undefined);
        } else {
            setSelectedPartner(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExperience, selectedDestination]);

    useEffect(() => {
        if (!isReady) return;
        const newPath = asPath.split('?')[0];
        if (newPath === currentPath || !destinations.length || !categories.length) return;
        setCurrentPath(newPath);
        setSearchAttractionData(isExperience);
    }, [
        currentPath,
        asPath,
        destinations.length,
        categories.length,
        isReady,
        pathname,
        setSearchAttractionData,
        isExperience,
    ]);

    useEffect(() => {
        setSelectedDateRange((prev) => {
            if (
                getFormattedRangeDate(prev?.dateStart) !== date.from ||
                date.to !== getFormattedRangeDate(prev?.dateEnd)
            ) {
                return getRangeDateBySlug(date);
            }
            return prev;
        });
    }, [date]);

    useEffect(() => {
        if (
            [
                '/partners',
                '/partners/[state]/[destination]',
                '/partners/[state]/[destination]/[slug]/[partnerId]',
            ].includes(pathname)
        ) {
            setExperience(false);
            setSearchAttractionData(false);
        } else {
            setExperience(true);
            setSearchAttractionData(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const contextProviderValue = useMemo(
        () => ({
            selectedDestination,
            selectedCategory,
            currentCategory,
            selectedPartner,
            selectedDateRange,
            recents,
            isExperience,
            destinationCategories,
            destinationPartners,
            setExperience,
            saveDestinationById,
            saveCategoryById,
            savePartnerById,
            saveDateRange,
            saveRecents,
            onRecentSearch,
            onSearch,
        }),
        [
            destinationCategories,
            destinationPartners,
            onRecentSearch,
            onSearch,
            isExperience,
            recents,
            saveCategoryById,
            saveDateRange,
            saveDestinationById,
            savePartnerById,
            saveRecents,
            selectedCategory,
            currentCategory,
            selectedDateRange,
            selectedDestination,
            selectedPartner,
        ]
    );

    return <SearchContext.Provider value={contextProviderValue}>{children}</SearchContext.Provider>;
};

export const SearchProvider = memo(SearchContextProvider);

export const useSearchContext = () => {
    const context = useContext(SearchContext);

    if (!context) {
        throw new Error('useThemeContext must be used within a ThemeProvider');
    }

    return context;
};
