import { isProd } from './envs';

export const STRIPE_KEY = isProd
    ? 'pk_live_r3tqBmy05RnhVvIMWZha0OMO'
    : 'pk_test_RTVcovXMT39KtjNv8584ASyl';

export const ITERABLE_KEY = isProd
    ? 'fb084b4971ca4a45996297a042520ebd'
    : '65617bc6915f49a9a8bae38aab965045';

export const CRS_KEY = isProd
    ? 'HhbUKT9Tmd3pGGdFgJr7N9uks3qZ7FnhOy9TBIsh'
    : 'ragwZgy0Wp3TH4Oa9Z6k68nRPhjuAmzG1UcrzXpt';

export const GW_CLIENT_KEY = isProd ? 'sdk-eMUofnGcKIjB967Q' : 'sdk-iRg5obwa2jcMCSY';
